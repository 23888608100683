export const stockData = [
  {
    "cateogry": "Scotch Whiskey",
    "productName": "100 Pipers",
    "brandNumber": "0370",
    "mrp": "2,420",
    "volumeml": 750,
    "stock": 815,
    "balance": 815
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "100 Pipers 12 Years",
    "brandNumber": 7458,
    "mrp": "3,200",
    "volumeml": 750,
    "stock": 100,
    "balance": 100
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Ballantines Finest",
    "brandNumber": 8096,
    "mrp": "2,200",
    "volumeml": 750,
    "stock": 742,
    "balance": 742
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Black & White 8 Years",
    "brandNumber": "0163",
    "mrp": "3,200",
    "volumeml": 750,
    "stock": 54,
    "balance": 54
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Black Dog Black Reserve (8 years)",
    "brandNumber": "0583",
    "mrp": "2,320",
    "volumeml": 750,
    "stock": 300,
    "balance": 300
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Black Dog Triple Gold Reserve (12 Years)",
    "brandNumber": 7292,
    "mrp": "3,170",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Blenders Pride",
    "brandNumber": "0361",
    "mrp": "1,450",
    "volumeml": 750,
    "stock": 133,
    "balance": 133
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Chivas Regal 12 Years",
    "brandNumber": 7191,
    "mrp": "5,920",
    "volumeml": 750,
    "stock": 1102,
    "balance": 1102
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Chivas Regal 18 Years",
    "brandNumber": 9604,
    "mrp": "8,320",
    "volumeml": 750,
    "stock": 48,
    "balance": 48
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Dewars 12 Years",
    "brandNumber": 8764,
    "mrp": "2,320",
    "volumeml": 750,
    "stock": 50,
    "balance": 50
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Johnnie Walker Black Label",
    "brandNumber": 8098,
    "mrp": "5,180",
    "volumeml": "1,000",
    "stock": 333,
    "balance": 333
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Johnnie Walker Blue Label",
    "brandNumber": 8664,
    "mrp": "21,850",
    "volumeml": "1,000",
    "stock": 50,
    "balance": 50
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Johnnie Walker Double Black",
    "brandNumber": 8120,
    "mrp": "4,960",
    "volumeml": "1,000",
    "stock": 175,
    "balance": 175
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Johnnie Walker Red Label",
    "brandNumber": 9099,
    "mrp": "2,760",
    "volumeml": "1,000",
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Teachers 50",
    "brandNumber": "0235",
    "mrp": "3,200",
    "volumeml": 750,
    "stock": 400,
    "balance": 400
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "Teachers Highland Cream",
    "brandNumber": "0239",
    "mrp": "2,310",
    "volumeml": 750,
    "stock": 400,
    "balance": 400
  },
  {
    "cateogry": "Scotch Whiskey",
    "productName": "William Lawsons",
    "brandNumber": 7128,
    "mrp": "2,160",
    "volumeml": 750,
    "stock": 300,
    "balance": 300
  },
  {
    "cateogry": "Single Malt Whiskey",
    "productName": "Glenfiddich 15 Years",
    "brandNumber": 8052,
    "mrp": "8,560",
    "volumeml": "1,000",
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Single Malt Whiskey",
    "productName": "Glenlivet 12 Years",
    "brandNumber": 7194,
    "mrp": "4,900",
    "volumeml": 750,
    "stock": 386,
    "balance": 386
  },
  {
    "cateogry": "Single Malt Whiskey",
    "productName": "Glenlivet 15 Years",
    "brandNumber": 9016,
    "mrp": "7,240",
    "volumeml": 700,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "American Whiskey",
    "productName": "J&B Rare",
    "brandNumber": 8121,
    "mrp": "3,130",
    "volumeml": 750,
    "stock": 25,
    "balance": 25
  },
  {
    "cateogry": "American Whiskey",
    "productName": "Jack Daniels Tenessee",
    "brandNumber": "0996",
    "mrp": "3,910",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "American Whiskey",
    "productName": "Jim Beam Black/White",
    "brandNumber": 7008,
    "mrp": "2,670",
    "volumeml": 750,
    "stock": 35,
    "balance": 35
  },
  {
    "cateogry": "Irish Whiskey",
    "productName": "Jamesons",
    "brandNumber": 8031,
    "mrp": "2,840",
    "volumeml": 750,
    "stock": 910,
    "balance": 910
  },
  {
    "cateogry": "Vodka",
    "productName": "Absolut",
    "brandNumber": 8006,
    "mrp": "2,510",
    "volumeml": 750,
    "stock": 1465,
    "balance": 1465
  },
  {
    "cateogry": "Vodka",
    "productName": "Grey Goose",
    "brandNumber": 9004,
    "mrp": "4,510",
    "volumeml": "1,000",
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Vodka",
    "productName": "Ketel One",
    "brandNumber": 8112,
    "mrp": "3,190",
    "volumeml": 750,
    "stock": 50,
    "balance": 50
  },
  {
    "cateogry": "Vodka",
    "productName": "Ciroc",
    "brandNumber": 0,
    "mrp": 0,
    "volumeml": 0,
    "stock": 100,
    "balance": 100
  },
  {
    "cateogry": "Vodka",
    "productName": "Alambac",
    "brandNumber": 0,
    "mrp": 0,
    "volumeml": 0,
    "stock": 150,
    "balance": 150
  },
  {
    "cateogry": "Vodka",
    "productName": "Smirnoff",
    "brandNumber": 7020,
    "mrp": "1,000",
    "volumeml": 750,
    "stock": 2450,
    "balance": 2450
  },
  {
    "cateogry": "Gin",
    "productName": "Beefeater Gin",
    "brandNumber": 8607,
    "mrp": "2,450",
    "volumeml": 750,
    "stock": 100,
    "balance": 100
  },
  {
    "cateogry": "Gin",
    "productName": "Bombay Sapphire",
    "brandNumber": 8001,
    "mrp": "2,680",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Gin",
    "productName": "Brokers London Dry Gin",
    "brandNumber": "0914",
    "mrp": "4,070",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Gin",
    "productName": "Gordon's Gin",
    "brandNumber": 8119,
    "mrp": "3,240",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Rum",
    "productName": "Bacardi Black",
    "brandNumber": 7505,
    "mrp": 710,
    "volumeml": 750,
    "stock": 588,
    "balance": 588
  },
  {
    "cateogry": "Rum",
    "productName": "Bacardi White",
    "brandNumber": 9740,
    "mrp": 0,
    "volumeml": 0,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Rum",
    "productName": "Hercules Bianco Superior White",
    "brandNumber": 374,
    "mrp": 930,
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Rum",
    "productName": "Captain Morgan",
    "brandNumber": "0044",
    "mrp": 530,
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Brandy",
    "productName": "Kyron",
    "brandNumber": "0398",
    "mrp": "1,300",
    "volumeml": 750,
    "stock": 391,
    "balance": 391
  },
  {
    "cateogry": "Brandy",
    "productName": "Mansion House",
    "brandNumber": "0070",
    "mrp": 580,
    "volumeml": 750,
    "stock": 300,
    "balance": 300
  },
  {
    "cateogry": "Brandy",
    "productName": "Morpheus",
    "brandNumber": "0338",
    "mrp": 980,
    "volumeml": 750,
    "stock": 350,
    "balance": 350
  },
  {
    "cateogry": "Tequila",
    "productName": "Camino Gold",
    "brandNumber": 7123,
    "mrp": "2,560",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Tequila",
    "productName": "Sauza Gold",
    "brandNumber": 8327,
    "mrp": "2,980",
    "volumeml": "1,000",
    "stock": 0,
    "balance": ""
  },
  {
    "cateogry": "Tequila",
    "productName": "Sauza Silver",
    "brandNumber": 8326,
    "mrp": "2,620",
    "volumeml": "1,000",
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Red Wine",
    "productName": "Jacobs Creek Shiraz",
    "brandNumber": 3753,
    "mrp": "1,500",
    "volumeml": 750,
    "stock": 130,
    "balance": 130
  },
  {
    "cateogry": "Red Wine",
    "productName": "Sula Cabernet Shiraz",
    "brandNumber": 3639,
    "mrp": "1,580",
    "volumeml": 750,
    "stock": 15,
    "balance": 15
  },
  {
    "cateogry": "White Wine",
    "productName": "Jacobs Creek Chardonnay",
    "brandNumber": 3752,
    "mrp": "1,500",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "White Wine",
    "productName": "Sula Chenin Blanc",
    "brandNumber": 3688,
    "mrp": "1,460",
    "volumeml": 750,
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Sparlking Wine",
    "productName": "Sula Brut Methode Traditionnelle",
    "brandNumber": 2667,
    "mrp": "1,850",
    "volumeml": 750,
    "stock": 5,
    "balance": 5
  },
  {
    "cateogry": "Sparlking Wine",
    "productName": "Jacob's Creek Chardonnay Pinot noir",
    "brandNumber": 3873,
    "mrp": "2,120",
    "volumeml": "1,000",
    "stock": 0,
    "balance": 0
  },
  {
    "cateogry": "Shots",
    "productName": "Balleys",
    "brandNumber": "BIC001",
    "mrp": "2,500",
    "volumeml": 750,
    "stock": 97,
    "balance": 97
  },
  {
    "cateogry": "Beer",
    "productName": "Bezawiser Ltr",
    "brandNumber": "BW013",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 1116,
    "balance": 1116
  },
  {
    "cateogry": "Beer",
    "productName": "Affida-wit Ltr",
    "brandNumber": "AW014",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 600,
    "balance": 600
  },
  {
    "cateogry": "Beer",
    "productName": "Bit-weet Ltr",
    "brandNumber": "BZ015",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 1411,
    "balance": 1411
  },
  {
    "cateogry": "Beer",
    "productName": "Farmhouse Ale Ltr",
    "brandNumber": "FA016",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 501,
    "balance": 501
  },
  {
    "cateogry": "Beer",
    "productName": "Brewer's Blend Ltr",
    "brandNumber": "BB085",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 600,
    "balance": 600
  },
  {
    "cateogry": "Beer",
    "productName": "Vault's Special Ltr",
    "brandNumber": "VS086",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 1941,
    "balance": 1941
  },
  {
    "cateogry": "Beer",
    "productName": "Bae of Bengal Ltr",
    "brandNumber": "BOB017",
    "mrp": 70,
    "volumeml": "1,000",
    "stock": 295,
    "balance": 295
  }
]