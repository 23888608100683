export const gridOrderStatus = (props) => (
  <span
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </span>
);

export const ordersData = [
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    Status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confirmed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confirmed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confirmed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    statusBg: '',
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confirmed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confirmed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confirmed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confirmed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confirmed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confirmed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
  {
    item: "100 Pipers",
    customerName: "Vineet",
    totalAmount: 82,
    status: "Pending",
    orderId: 1,
    table: 1,
  },
  {
    item: "100 Pipers 12 Years",
    customerName: "Vineet",
    totalAmount: 45,
    status: "Confirmed",
    orderId: 2,
    table: 2,
  },
  {
    item: "Ballantines Finest",
    customerName: "Vineet",
    totalAmount: 234,
    status: "Cancelled",
    orderId: 3,
    table: 1,
  },
  {
    item: "Black & White 8 Years",
    customerName: "Vineet",
    totalAmount: 246,
    status: "Cancelled",
    orderId: 4,
    table: 1,
  },
  {
    item: "Black Dog Black Reserve (8 years)",
    customerName: "Vineet",
    totalAmount: 356,
    status: "Confimed",
    orderId: 5,
    table: 3,
  },
  {
    item: "Black Dog Triple Gold Reserve (12 Years)",
    customerName: "Vineet",
    totalAmount: 56,
    status: "Confimed",
    orderId: 6,
    table: 4,
  },
  {
    item: "Blenders Pride",
    customerName: "Nancy",
    totalAmount: 456,
    status: "Confimed",
    orderId: 7,
    table: 7,
  },
  {
    item: "Chivas Regal 12 Years",
    customerName: "Nancy",
    totalAmount: 55,
    status: "Brewing",
    orderId: 8,
    table: 5,
  },
  {
    item: "Chivas Regal 18 Years",
    customerName: "Nancy",
    totalAmount: 234,
    status: "Brewing",
    orderId: 9,
    table: 7,
  },
  {
    item: "Dewars 12 Years",
    customerName: "Nancy",
    totalAmount: 886,
    status: "Brewing",
    orderId: 10,
    table: 8,
  },
  {
    item: "Johnnie Walker Black Label",
    customerName: "Akshay",
    totalAmount: 53,
    status: "Brewing",
    orderId: 11,
    table: 9,
  },
  {
    item: "Johnnie Walker Blue Label",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Brewing",
    orderId: 12,
    table: 11,
  },
  {
    item: "Johnnie Walker Double Black",
    customerName: "Akshay",
    totalAmount: 646,
    status: "Brewing",
    orderId: 13,
    table: 23,
  },
  {
    item: "Johnnie Walker Red Label",
    customerName: "Akshay",
    totalAmount: 342,
    status: "Completed",
    orderId: 14,
    table: 12,
  },
  {
    item: "Teachers 50",
    customerName: "Akshay",
    totalAmount: 379,
    status: "Completed",
    orderId: 15,
    table: 14,
  },
  {
    item: "Teachers Highland Cream",
    customerName: "Akshay",
    totalAmount: 436,
    status: "Completed",
    orderId: 16,
    table: 4,
  },
  {
    item: "William Lawsons",
    customerName: "Akshay",
    totalAmount: 34,
    status: "Completed",
    orderId: 17,
    table: 6,
  },
  {
    item: "Glenfiddich 15 Years",
    customerName: "Akshay",
    totalAmount: 689,
    status: "Completed",
    orderId: 18,
    table: 19,
  },
  {
    item: "Glenlivet 12 Years",
    customerName: "Akshay",
    totalAmount: 235,
    status: "Completed",
    orderId: 19,
    table: 29,
  },
  {
    item: "Glenlivet 15 Years",
    customerName: "Akshay",
    totalAmount: 547,
    status: "Completed",
    orderId: 20,
    table: 4,
  },
  {
    item: "J&B Rare",
    customerName: "Luna",
    totalAmount: 345,
    status: "Completed",
    orderId: 21,
    table: 22,
  },
  {
    item: "Jack Daniels Tenessee",
    customerName: "Luna",
    totalAmount: 673,
    status: "Completed",
    orderId: 22,
    table: 17,
  },
  {
    item: "Jim Beam Black/White",
    customerName: "Luna",
    totalAmount: 523,
    status: "Brewing",
    orderId: 23,
    table: 8,
  },
];
