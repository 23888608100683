import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Sort,
  Search,
  Toolbar,
  Filter,
  Edit
} from "@syncfusion/ej2-react-grids";
import { customersGrid } from "../data/dummy";
import { stockData } from '../extras/products'
import Header from "../components/Header";

const Stocks = () => {
    const editing = { allowDeleting: true, allowEditing: true };
  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Stocks" category="Inventory" />
      <GridComponent
        dataSource={stockData}
        allowPaging
        editSettings={editing}
        allowSorting
        toolbar={["Search"]}
        width="auto"
      >
        <ColumnsDirective>
          {customersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Sort, Toolbar, Search, Filter, Edit]} />
      </GridComponent>
    </div>
  );
};

export default Stocks;
